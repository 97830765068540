/* .swiper-container .swiper-button-prev, .swiper-container-rtl {
    right: 5px;
}

 .swiper-container .swiper-button-next, .swiper-container-rtl {
    left: 5px;
} */

.swiper-slide{
    padding: 0 15px;
}

.swiper-button-prev:after, .swiper-button-next:after {
    font-size: 10px;
    background-color: rgba(177, 169, 176, 0.838);
    padding: 7px 10px;
    border-radius: 50%;
    color: #fff;
}

.swiper-button-disabled{
    display: none;
}