:root {
--achim-blue: #3fb4e3;
--achim-green: #7ebc86;
--achim-pink: #cf4e98;
--achim-orange: #d9795e;
}

.load-text{
  animation: slide 5s linear infinite alternate ;
}
@keyframes slide {
  0%{
    color: var(--achim-blue);
  }
  25%{
      color: var(--achim-green);
  }
  50%{
      color: var(--achim-pink);
  }
  75%{
      color: var(--achim-orange);
  }
  100%{
    color: var(--achim-blue);
  }
}

option{
  font-size: 1.2rem;
}

.pending{
  z-index: 500;
  background-color: rgba(255, 255, 255, 0.77);
}

.loader2{
  z-index: 500;
/*   background-color: rgba(255, 255, 255, 0.77); */
}

.pending-load{
  background-color: rgba(255, 255, 255);
}

.bell-icon {
  position: relative;
}

.bell-icon:after {
  content: attr(data-bell-counter);
  font-family: 'Courier New', Courier, monospace;
  top: -10px;
  right: 10px;
  position: absolute;
  font-size: 0.85rem;
  height: 20px;
  width: 20px;
  background: red;
  color: white;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.nav-logo{
  position: absolute;
  align-items: center;
  left: calc(50% - 34px);
}

.achim-shadow{
  box-shadow: 1px 1px 10px 2px rgba(0,0,0,0.23) !important;
  -webkit-box-shadow: 1px 1px 10px 2px rgba(0,0,0,0.23);
  -moz-box-shadow: 1px 1px 10px 2px rgba(0,0,0,0.23);
}

.achim-shadow:hover{
  box-shadow: 1px 1px 18px 2px rgba(0,0,0,0.23) !important;
  -webkit-box-shadow: 1px 1px 15px 2px rgba(0,0,0,0.22);
  -moz-box-shadow: 1px 1px 15px 2px rgba(0,0,0,0.22);
}

.study-slider:hover{
   transform: scale(1.03);
   transition: all 1s;
}

.card-bg-image:hover .opacity-4:hover{
  opacity: 0.8;
}

.alert-dismissible .close{
  right: 10px !important;
  left: 0 !important;
  top: 50% !important;
}

.card {
	-webkit-animation: slide-top 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-top 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.slide-top {
	-webkit-animation: slide-top-b 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-top-b 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes slide-top-b {
  0% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes slide-top-b {
  0% {
    -webkit-transform: translateY(100px);
            transform: translateY(100px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}


.slide-down {
	-webkit-animation: slide-down-b 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-down-b 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

 @-webkit-keyframes slide-down-b {
  0% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}
@keyframes slide-down-b {
  0% {
    -webkit-transform: translateY(-100px);
            transform: translateY(-100px);
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
}

.shadow-xl{
  box-shadow: 0rem 0.5rem 1rem rgba(0, 5, 10, 0.37) !important; }

  .hover-row-class:hover{
    background-color: #3fb5e328;
  }

  .button-group-1{
    outline: none !important;
    border: none !important;
    background-color: #cf4e9865 !important;
    color: #6e0c51 !important;
  }

  .button-group-1-active{
    background-color: var(--achim-pink) !important;
    color: white !important;
  }

  .button-group-1:hover{
    background-color: var(--achim-pink) !important;
    color: #6e0c51 !important;
  }

  .button-group-2{
    background-color: #d9795e65 !important;
    color: #d16d52 !important;
  }

  .button-group-2-active{
    background-color: var(--achim-orange) !important;
    color: white !important;
  }

  .button-group-2:hover{
    background-color: var(--achim-orange) !important;
    color: #442803 !important;

  }


  .scale-in-center {
    -webkit-animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
            animation: scale-in-center 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  /**
 * ----------------------------------------
 * animation scale-in-center
 * ----------------------------------------
 */
@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}
@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 1;
  }
}

.img-anima1{
  overflow: hidden;
}

.img-anima1 .image-col{
  clip-path: polygon(0 0, 100% 0, 74% 100%, 0% 100%);


}
.img-anima3:hover .img-anima4{
  opacity: 0.6 !important;
  transform: scale(1.1);
  transition: all 0.8s ease-in-out;
}

.img-anima {
  -webkit-animation: img-anima-scale 10s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite both;
          animation: img-anima-scale 10s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite both;
}

@-webkit-keyframes img-anima-scale {
0% {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
}
50% {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  opacity: 1;
}
100% {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
}
}
@keyframes img-anima-scale {
0% {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
}
50% {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
  opacity: 1;
}
100% {
  -webkit-transform: scale(1);
          transform: scale(1);
  opacity: 1;
}
}

.modal-backdrop
{
    opacity:0.8 !important;
}

@media (min-width: 992px) {
.fixed-bottom-lg {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }
}

@media (max-width: 992px) {
  .button-group-1
  {
      font-size: .875rem !important;
      line-height: 1.5 !important;
  
      padding: .25rem .5rem !important;
  
      border-radius: .2rem !important;
  }
}

.pink.nav-link {
    color: var(--achim-pink) !important;
    background-color: #fff !important;
  }
.active.pink.nav-link {
    color: #fff !important;
    background-color: var(--achim-pink) !important;
  }

  .alert-index{
    z-index: 10000 !important;
  }