//
// Type
//


// Paragraphs

p {
	font-size: $paragraph-font-size;
	font-weight: $paragraph-font-weight;
	line-height: $paragraph-line-height;
}

.lead {
	font-size: $lead-font-size;
  	font-weight: $lead-font-weight;
	line-height: $paragraph-line-height;
	margin-top: 1.5rem;

	+ .btn-wrapper {
		margin-top: 3rem;
	}
}

.description {
	font-size: $font-size-sm;
}

.display-0 {
    font-size: 8.3rem;
    font-weight: $display0-weight;
    line-height: 1; }
    @media (max-width: 1200px) {
      .display-0 {
        font-size: calc(5.455rem + 2.46vw) ; } }
