//
// Backgrounds
//


@each $color, $value in $colors {
	@include bg-variant(".bg-#{$color}", $value);
}

@each $color, $value in $theme-colors {
	@include bg-gradient-variant(".bg-gradient-#{$color}", $value);
}

@each $color, $value in $colors {
	@include bg-gradient-variant(".bg-gradient-#{$color}", $value);
}


// Background colors with transparency

@each $color, $value in $theme-colors {
    @include bg-translucent-variant(".bg-translucent-#{$color}", $value);
}


// Sections backgrounds

@each $color, $value in $section-colors {
    @include bg-variant(".section-#{$color}", $value);
}

@each $color, $value in $theme-colors {
    @include bg-gradient-variant(".bg-gradient-#{$color}", $value);
}


// Shape (svg) fill colors

@each $color, $value in $theme-colors {
    .fill-#{$color}  {
        fill: $value;
    }

    .stroke-#{$color}  {
        stroke: $value;
    }
}

.fill-opacity-8 {
	fill-opacity: .8;
}

.bg-gradient-achim-pink {
    background: linear-gradient(87deg, $achim-pink-print 0, $achim-pink 100%) !important; 
}

.bg-gradient-achim-orange {
    background: linear-gradient(87deg, $achim-orange-print 0, $achim-orange 100%) !important;
}

.bg-achim-blue{
    background-color: $achim-blue;
}
.bg-achim-bluee-print{
    background-color: $achim-blue-print;
}
.bg-achim-green{
    background-color: $achim-green;
}
.bg-achim-greene-print{
    background-color: $achim-green-print;
}
.bg-achim-pink{
    background-color: $achim-pink;
}
.bg-achim-pinke-print{
    background-color: $achim-pink-print;
}
.bg-achim-orange{
    background-color: $achim-orange;
}
.bg-achim-orangee-print{
    background-color: $achim-orange-print;
}
.bg-achim-black{
    background-color: $achim-black;
}
.bg-achim-blacke-print{
    background-color: $achim-black-print;
}
    
